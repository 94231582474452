/** @format */

import ApiClient from "./HttpInstance";

const apiClient = new ApiClient("http://120.48.77.226:5050");
// const apiClient = new ApiClient("http://499b6d66.r22.cpolar.top");
// const apiLocal = new ApiClient("http://7bd067.r3.cpolar.top/");//本地
// const apiLocal = new ApiClient("http://210.22.149.156:8085");//测试服务器
// const apiLocal = new ApiClient("http://192.168.1.20:8000");
const apiLocal = new ApiClient("https://api.iproteome.com/financial");//正式
//用户登录
export const UserLogin = (data: any) => apiLocal.post("/center/login/", data);
//获取用户信息
export const getUserInfo = (data?: { [key: string]: any }) =>
	apiLocal.get("/center/get_user_info/", data);
//数据库连接
export const databaseConnection = (data: { [key: string]: any }) =>
	apiClient.post("/dataBase/connectToExternalDatabase/", data);

//查看用户创建的数据库
export const getAllDataBase = (data?: { [key: string]: any }) =>
	apiClient.get("/dataBase/getAllDatabase/", data);
//查询数据库的所有表
export const getDataBaseTables = (id?: string, data?: { [key: string]: any }) =>
	apiClient.get(`/dataBase/getDataBaseTables/${id}/`, data);
//创建文件夹
export const user_create_folder = (data: { [key: string]: any }) =>
	apiLocal.post("/file/user_create_folder/", data);
//datasets 数据集列表
export const user_all_folder = (data?: { [key: string]: any }) =>
	apiLocal.get("/file/get_file_info/", data);
//删除数据源
export const delete_file_info = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.delete(`/file/delete_file_info/${id}/`, data);
//查询文件夹下的内容
export const get_folder_info = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.get(`/file/get_file_info/${id}/`, data);
//查询文件详情
export const get_file_details = (
	id: string | null,
	data?: { [key: string]: any },
) => apiLocal.get(`/file/get_file_details/${id}/`, data);
//上传文件
export const uploadFile = (data?: { [key: string]: any }) =>
	apiLocal.post("/file/upload_file/", data, {
		"Content-Type": "multipart/form-data",
	});
//上传文件
export const merage = (data?: { [key: string]: any }) =>
	apiLocal.get("/file/merge_file/", data);
//文件与数据集绑定
export const put_file_details = (
	id: string | undefined,
	data: { [key: string]: any },
) => apiLocal.put(`/file/put_file_details/${id}/`, data);
//拿取源数据
export const getMetaData = (id: string | null, data?: { [key: string]: any }) =>
	apiClient.get(`/datasets/getMetaData/${id}/`, data);

//保存图表
export const add_graph_info = (data: { [key: string]: any }) =>
	apiLocal.post("/file/add_graph_info/", data);
//获取图表列表
export const findAllCharts = (data?: { [key: string]: any }) =>
	apiLocal.get("/file/add_graph_info/", data);
//获取图表详情
export const getChartsDetails = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.get(`/file/graph_info/${id}/`, data);
//删除图表
export const deleteCharts = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.delete(`/file/graph_info/${id}/`, data);
//修改图表
export const putCharts = (
	id: string | undefined,
	data: { [key: string]: any },
) => apiLocal.put(`/file/graph_info/${id}/`, data);
//新增dashboards
export const createDashboards = (data: { [key: string]: any }) =>
	apiLocal.post("/file/dashboard_info/", data);
//查询用户创建的dashboards
export const findMyDashboards = (data?: { [key: string]: any }) =>
	apiLocal.get("/file/dashboard_info/", data);
//根据id查询dashboards的details
export const findDashboardsDetails = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.get(`/file/dashboard_info/${id}/`, data);
//删除dashboards
export const deleteDashboards = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.delete(`/file/dashboard_info/${id}/`, data);
//查询子看板
export const findSubDashboards = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.get(`/file/get_dashboard_info/${id}/`, data);
//保存看板详情
export const saveDashboardsDetails = (
	id: string | undefined,
	data: { [key: string]: any },
) => apiLocal.put(`/file/dashboard_info/${id}/`, data);
//控制是否发布
export const publishDashboards = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.put(`/file/publish_or_not/${id}/`, data);
//获取所有已发布的看板
export const findAllPublishedDashboards = (data?: { [key: string]: any }) =>
	apiLocal.get("/file/get_all_publish_yes/", data);
//获取毛利润 
export const CaptureGrossProfit = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/gross_profit/", data);
//获取固定资产 
export const ForFixedAssets = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/fixed_asset_structure/", data);
//获取银行流水 
export const GetBankStatement = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/bank_statements/", data);
//获取重要指标数据
export const ImportantBusinessIndicatorsData = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/business_indicators/", data);
	//获取利润构成
export const GetProfitComposition = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/profit_composition/", data);
//获取毛利率 
export const GetGrossMargin = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/gross_profit_margin/", data);
//获取净利润 
export const CaptureNetProfit = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/net_profit/", data);
//获取净利率 
// export const GetNetInterestRates = (data?:{year:string,month:string}) =>
// 	apiLocal.get("/large_data_screen/net_profit_margin/", data);
//获取应收账款周天数
export const GetAccountsReceivableWeekDays= (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/accounts_receivable_days/", data);
//获取主营业收入 
export const CaptureTheMainOperatingIncome = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/main_operating_income/", data);
//获取主营业成本 
export const GetTheMainOperatingCost = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/main_operating_cost/", data);
//获取净资产收益率
export const GetReturnOnEquity = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/return_on_equity/", data);
//获取营业净利率
export const EarnANetOperatingMargin = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/net_operating_margin/", data);
//获取总资产周转率
export const ObtainTotalAssetTurnover = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/total_asset_turnover/", data);
//获取权益乘数
export const GetTheEquityMultiplier = (data?:{year:string,month:string}) =>
	apiLocal.get("/large_data_screen/equity_multiplier/", data);

//获取往年盈利(金额)
export const GetPreviousYearsProfitAmount = (data?:{year:string}) =>
	apiLocal.get("/large_data_screen/profitability_previous_year/", data);
//获取往年盈利(利润率)
export const GetNormalProfitMargins = (data?:{year:string}) =>
	apiLocal.get("/large_data_screen/profitability_previous_profit_margin_year/", data);
//往年盈利趋势（权益）
export const PastEarningsTrends = (data?:{year:string}) =>
	apiLocal.get("/large_data_screen/profitability_previous_trend/", data);

//查看子看板
export const findSubDashboardsDetails = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.get(`/file/get_children_publish_yes/${id}/`, data);
//查询所有的用户
export const findAllUsers = (data?: { [key: string]: any }) =>
	apiLocal.get("/center/get_all_local_user_info/", data);
//查询所有的角色
export const findAllRoles = (data?: { [key: string]: any }) =>
	apiLocal.get("/center/get_all_local_role_info/", data);
//查询所有的权限
export const findAllPermissions = (data?: { [key: string]: any }) =>
	apiLocal.get("/center/get_all_route_info/", data);
//新增路由信息
export const createRoute = (data: { [key: string]: any }) =>
	apiLocal.post("/center/save_route_role/", data);
//删除路由信息
export const deleteRoute = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.delete(`/center/delete_route_role/${id}/`, data);

//根据角色id查询角色权限
export const findRolePermissions = (
	id: string | undefined,
	data?: { [key: string]: any },
) => apiLocal.get(`/center/get_role_route_info/${id}/`, data);
//角色与路由关联
export const roleRoute = (data: { [key: string]: any }) =>
	apiLocal.post("/center/correlation_route_role/", data);

//查询所有数据集
export const findAllDatasets = (data?: { [key: string]: any }) =>
	apiLocal.get("/file/filter_file_details/", data);

//根据年份、月份寻找文件名，并返回指定列数据
// @ts-ignore
export const findFileByYearAndMonth = (data: { [key: string]: any }) =>
	apiLocal.get("/file/get_excel_data/", data);

//将看板数据导出为excel
export const exportExcel = (data: { [key: string]: any }) =>
	apiLocal.post("/file/dashboard_export_excel/", data);
//销售指标达标情况
export const get_revenue_target = (data?: { [key: string]: any }) =>
	apiLocal.get("/report_automation/get_revenue_target/", data);
//查询单个销售指标情况
export const single_revenue_target = (id: string | undefined,data?: { [key: string]: any }) =>
	apiLocal.get(`/report_automation/single_revenue_target/${id}/`, data);
//修改单个销售指标情况
export const put_single_revenue_target = (id: string | undefined,data?: { [key: string]: any }) =>
	apiLocal.put(`/report_automation/single_revenue_target/${id}/`, data);
//指标达标情况-总和
export const get_revenue_target_department = (data?: { [key: string]: any }) =>
	apiLocal.get(`/report_automation/get_revenue_target_department/`, data);
//获取人员姓名以及部门情况
export const get_all_user = (data?: { [key: string]: any }) =>
	apiLocal.get(`/center/get_all_user/`, data);
//查询所有开票回款记录
export const invoice_collection_record_operate = (data?: { [key: string]: any }) =>
	apiLocal.get(`/report_automation/invoice_collection_record_operate/`, data);
//更新开票回款记录
export const put_single_invoice_collection_record = (id: string | undefined,data?: { [key: string]: any }) =>
	apiLocal.put(`/report_automation/single_invoice_collection_record/${id}/`, data);
//删除开票回款记录
export const del_single_invoice_collection_record = (id: string | undefined) =>
	apiLocal.delete(`/report_automation/single_invoice_collection_record/${id}/`);
//上传开票回款记录
export const single_invoice_collection_record_file_upload = (data?: { [key: string]: any, }) =>
	apiLocal.post(`/report_automation/file_upload/`,data, {
		"Content-Type": "multipart/form-data",
	});
//合并开票回款记录
export const single_invoice_collection_record_file_merge = (data?: { [key: string]: any, }) =>
	apiLocal.put(`/report_automation/file_merge/`,data);
//导出开票回款记录
export const export_file = (data: { [key: string]: any, }) =>
	apiLocal.get(`/report_automation/export_file/`,data);
//导出开票回款记录
export const invoice_collection_record_many_operate = (data: { [key: string]: any, }) => 
	apiLocal.post(`/report_automation/invoice_collection_record_many_operate/`,data); 
//批量新建开票回款
export const get_signle_sale_person_details = (data: { [key: string]: any, }) => 
	apiLocal.get(`/report_automation/get_signle_sale_person_details/`,data); 
//销售人员查看具体阶段具体时间明细 
export const ErrorLog = (data: { [key: string]: any, }) => 
	apiLocal.post(`/center/error_catch/`,data); 
//页面报错 
export const get_invoiced_person_msg = (data: { [key: string]: any, }) => 
	apiLocal.get(`/report_automation/get_invoiced_person_msg/`,data); 
//根据合同号备注填充数据
export const get_company_province = (data: { [key: string]: any, }) => 
	apiLocal.get(`/report_automation/get_company_province/`,data); 
//填充省份
export const invoice_or_returned_visualization_line_chart = (data: { [key: string]: any, }) => 
	apiLocal.get(`/report_automation/invoice_or_returned_visualization_line_chart/`,data); 
//开票回款趋势图
export const get_accounts_receivable = (data: { [key: string]: any, }) => 
	apiLocal.get(`/report_automation/get_accounts_receivable/`,data); 
//开票回款趋势图