/** @format */

import * as React from "react";
const LoginNav: React.FC = () => {
	return (
		<nav className='bp4-navbar bp4-dark'>
			<div className='margin: 0 auto; width: 480px;'>
				<div className='bp4-navbar-group bp4-align-left'>
					<div className='bp4-navbar-heading'>爱谱蒂康财务中心</div>
				</div>
				<div className='bp4-navbar-group bp4-align-right'></div>
			</div>
		</nav>
	);
};
export default LoginNav;
