/** @format */

import { createSlice } from "@reduxjs/toolkit";
interface cacheData {
	data: { [key: string]: any };
}
const initialState: cacheData = {
	data: {},
};
const cacheDataSlice = createSlice({
	name: "cacheData",
	initialState,
	reducers: {
		addCacheData: (state, data) => {
			state.data = data.payload;
		},
	},
});

export const { addCacheData } = cacheDataSlice.actions;
export default cacheDataSlice.reducer;
