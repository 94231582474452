/** @format */

import * as React from "react";
import { NavLink } from "react-router-dom";
import { Popover2 } from "@blueprintjs/popover2";
import { Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { getUserInfo } from "src/api/api";
import cookie from "react-cookies";

type MyProps = {};
type Mystate = {
	role_list: Array<any>;
};
export default class Nav extends React.Component<MyProps, Mystate> {
	state: Readonly<Mystate> = {
		role_list: [],
	};
	public componentDidMount(): void {
		getUserInfo().then((res: any) => {
			sessionStorage.setItem("useInfo", JSON.stringify(res.data));
			sessionStorage.setItem("tag", res.permission_dict.tag);
			sessionStorage.setItem("details", res.permission_dict.details);
			this.setState({
				role_list: res.data.roles,
			});
		});
	}
	public render(): React.ReactNode {
		const tag: any = sessionStorage.getItem('tag');
		const details: any = sessionStorage.getItem('details');
		
		const { role_list } = this.state;
		const exampleMenu = (
			<Menu>
				{/* <MenuItem icon='graph' text='Graph' />
				<MenuItem icon='map' text='Map' />
				<MenuItem icon='th' text='Table' shouldDismissPopover={false} />
				<MenuItem
					icon='database'
					text='database'
					onClick={() => {
						window.location.href = "/database";
					}}
				/>
				<MenuDivider /> */}
				<MenuItem
					icon='cube'
					text='后台管理'
					onClick={() => {
						window.location.href = "/admin";
						sessionStorage.setItem("role", "a");
					}}
				/>
				<MenuItem
					icon='mugshot'
					text='用户管理'
					onClick={() => {
						window.location.href = "/permission";
					}}
				/>
				<MenuDivider />
				<MenuItem
					onClick={() => {
						window.location.href = "/";
						cookie.remove("datacenter_token");
					}}
					icon='log-out'
					text='登出'
				/>
			</Menu>
		);
		return (
			<>
				<nav className='bp4-navbar ' style={{ height: "50px" }}>
					{sessionStorage.getItem("role") == "a" ? (
						<div
							className='bp4-navbar-group bp4-align-left'
							style={{ height: "50px" }}>
							<div className='bp4-navbar-heading'>
								<a
									onClick={() => {
										window.location.href = "/home";
										sessionStorage.setItem("role", "e");
									}}>
									爱谱蒂康财务中心
								</a>
							</div>

							<NavLink to='/admin'>
								<button className='bp4-button bp4-minimal bp4-icon-dashboard'>
									报表库
								</button>
							</NavLink>
							<NavLink to='/charts'>
								<button className='bp4-button bp4-minimal bp4-icon-chart'>
									图表
								</button>
							</NavLink>
							<NavLink to='/datasets'>
								<button className='bp4-button bp4-minimal bp4-icon-th-derived'>
									数据集
								</button>
							</NavLink>
						</div>
					) : (
						<div
							className='bp4-navbar-group bp4-align-left'
							style={{ height: "50px" }}>
							<div className='bp4-navbar-heading'>
								<a>爱谱蒂康财务中心</a>
							</div>
							{(tag==1||(details!=null&&details.includes("驾驶舱")))&& <NavLink to='/home/dashboards'>
								<button className='bp4-button bp4-minimal bp4-icon-applications'>
									驾驶舱
								</button>
							</NavLink>}
							{(tag==1||(details!=null&&details.includes("利润中心")))&& <NavLink to='/home/LargeDataScreen'>
								<button className='bp4-button bp4-minimal bp4-icon-chart'>
									利润中心
								</button>
							</NavLink>}
							{(tag==1||(details!=null&&details.includes("现金分析")))&& <NavLink to='/home/CashAnalysis'>
								<button className='bp4-button bp4-minimal bp4-icon-chart'>
									现金分析
								</button>
							</NavLink>}
							{(tag==1||(details!=null&&details.includes("成本分析")))&& <NavLink to='/home/CostAnalysis'>
								<button className='bp4-button bp4-minimal bp4-icon-series-search'>
									成本分析
								</button>
							</NavLink>}
							{(tag==1||(details!=null&&details.includes("开票回款日报")))&& <NavLink to='/home/InvoicingDaily'>
								<button className='bp4-button bp4-minimal bp4-icon-chart'>
									开票回款日报
								</button>
							</NavLink>}
							{/* <NavLink to='/home/FinancialStatement'>
								<button className='bp4-button bp4-minimal bp4-icon-pie-chart'>
									财务报表
								</button>
							</NavLink> */}
						</div>
					)}

					<div
						className='bp4-navbar-group bp4-align-right'
						style={{ height: "50px" }}>
						<input
							className='bp4-input'
							placeholder='请输入您的关键词...'
							type='text'
						/>
						<span className='bp4-navbar-divider'></span>
						<button className='bp4-button bp4-minimal bp4-icon-user'></button>
						<button className='bp4-button bp4-minimal bp4-icon-notifications'></button>
						<Popover2 content={exampleMenu} fill={true} placement='bottom'>
							<button className='bp4-button bp4-minimal bp4-icon-cog'></button>
						</Popover2>
					</div>
				</nav>
			</>
		);
	}
}
