/** @format */

import { createSlice } from "@reduxjs/toolkit";
interface CsvData {
	data: Array<[]>;
}
const initialState: CsvData = {
	data: [],
};
const csvDataSlice = createSlice({
	name: "csvData",
	initialState,
	reducers: {
		addCsvData: (state, data) => {
			state.data = data.payload;
		},
	},
});

export const { addCsvData } = csvDataSlice.actions;
export default csvDataSlice.reducer;
