/** @format */

import { createSlice } from "@reduxjs/toolkit";
interface multiTableComparison {
	data: { [key: string]: any };
}
const initialState: multiTableComparison = {
	data: {},
};
const multiTableComparisonSlice = createSlice({
	name: "multiTableComparison",
	initialState,
	reducers: {
		addmultiTableComparison: (state, data) => {
			state.data = data.payload;
		},
	},
});

export const { addmultiTableComparison } = multiTableComparisonSlice.actions;
export default multiTableComparisonSlice.reducer;
