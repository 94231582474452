/** @format */

import * as React from "react";
import "./App.less";
import Nav from "./components/Nav";
import Main from "./main";
import LoginNav from "./components/LoginNav";
import cookie from "react-cookies";

type MyProps = {};
type Mystate = {
	isLogin: Boolean;
};
class App extends React.Component<MyProps, Mystate> {
	state: Readonly<Mystate> = {
		isLogin: cookie.load("datacenter_token") ? true : false,
	};
	public render(): React.ReactNode {
		const { isLogin } = this.state;
		return (
			<div className='App'>
				{isLogin ? <Nav /> : <LoginNav />}

				<Main />
			</div>
		);
	}
}
export default App;
