/** @format */

import { createSlice } from "@reduxjs/toolkit";
interface SelectData {
	data: Array<any>;
}
const initialState: SelectData = {
	data: [],
};
const selectDataSlice = createSlice({
	name: "selectData",
	initialState,
	reducers: {
		increment: (state, data) => {
			state.data = data.payload;
		},
	},
});

export const { increment } = selectDataSlice.actions;
export default selectDataSlice.reducer;
