/** @format */

import { createSlice } from "@reduxjs/toolkit";
interface TableData {
	data: Array<[]>;
}
const initialState: TableData = {
	data: [],
};
const tableDataSlice = createSlice({
	name: "tableData",
	initialState,
	reducers: {
		addData: (state, data) => {
			state.data = [...state.data, data.payload];
		},
	},
});

export const { addData } = tableDataSlice.actions;
export default tableDataSlice.reducer;
