/** @format */

import { Toaster, Position, Intent } from "@blueprintjs/core";

export const Procuretoast = (messgae: any, intent: Intent) =>
	Toaster.create({
		className: "recipe-toaster",
		position: Position.TOP,
	}).show({
		message: messgae,
		intent: intent,
	});
