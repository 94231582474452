/** @format */

import { combineReducers } from "redux";
import counterReducer from "./counter";
import selectDataReducer from "./selectData";
import tableDataReducer from "./tableData";
import csvDataReducer from "./csvData";
import cacheDataReducer from "./cacheData";
import multiTableComparisonSlice from "./multiTableComparison";

const rootReducer = combineReducers({
	counter: counterReducer,
	selectData: selectDataReducer,
	tableData: tableDataReducer,
	csvData: csvDataReducer,
	cacheData: cacheDataReducer,
	multiTableComparison: multiTableComparisonSlice,
});

export default rootReducer;
